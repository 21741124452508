import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import {
  IAggregateSaleData,
  IAggregateSaleDataParams,
  IAggregateSaleContrastData,
  IAggregateSupplierContrastData,
  IAggregateSaleTrendParams,
  IAggregateSaleTrend,
  ISuppliertHour,
  ISupplierContrastHourParams,
  ILastHourFeed,
  IHourFeed,
  IFeedForHour
} from './type'

enum DashboardApi {
  getAggregateSaleData = '/purchaseData/aggregatePurchaseData',
  getAggregateSaleContrastData = '/purchaseData/aggregatePurchaseContrastData',
  getAggregateSupplierContrast = '/purchaseData/aggregateSupplierContrast',
  getAggregateSaleTrend = '/purchaseData/aggregatePurchaseTrend',
  getAggregateSupplierContrastHour = '/purchaseData/aggregateSupplierContrastHour',
  findLastHourFeed = '/purchaseData/findLastHourFeed',
  getHourFeed = '/purchaseData/hourFeed',
  findFeedForHour = '/purchaseData/findFeedForHour'
}

// 骨料汇总及各料类销售数据
export function getAggregateSaleData(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<IAggregateSaleData>> {
  return hyRequest.post<IRequestResult<IAggregateSaleData>>({
    url: DashboardApi.getAggregateSaleData,
    data
  })
}

//骨料销售对比数据
export function getAggregateSaleContrastData(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<IAggregateSaleContrastData>> {
  return hyRequest.post<IRequestResult<IAggregateSaleContrastData>>({
    url: DashboardApi.getAggregateSaleContrastData,
    data
  })
}

//供应商料类采购排行榜
export function getAggregateSupplierContrast(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<IAggregateSupplierContrastData>> {
  return hyRequest.post<IRequestResult<IAggregateSupplierContrastData>>({
    url: DashboardApi.getAggregateSupplierContrast,
    data
  })
}
//骨料销售趋势数据
export function getAggregateSaleTrend(
  data: IAggregateSaleTrendParams
): Promise<IRequestResult<IAggregateSaleTrend>> {
  return hyRequest.post<IRequestResult<IAggregateSaleTrend>>({
    url: DashboardApi.getAggregateSaleTrend,
    data: data
  })
}

//供应商料类采购小时趋势
export function getAggregateSupplierContrastHour(
  data: ISupplierContrastHourParams
): Promise<IRequestResult<ISuppliertHour>> {
  return hyRequest.post<IRequestResult<ISuppliertHour>>({
    url: DashboardApi.getAggregateSupplierContrastHour,
    data: data
  })
}

//上一小时各供应商进料情况

export function findLastHourFeed(): Promise<IRequestResult<ILastHourFeed>> {
  return hyRequest.post<IRequestResult<ILastHourFeed>>({
    url: DashboardApi.findLastHourFeed
  })
}

//各小时进料情况
export function getHourFeed(
  params: IAggregateSaleDataParams
): Promise<IRequestResult<IHourFeed>> {
  return hyRequest.post<IRequestResult<IHourFeed>>({
    url: DashboardApi.getHourFeed,
    data: params
  })
}

//根据日期和小时查询供应商原石进料情况
export function findFeedForHour(params: {
  date: string
  hour: string
}): Promise<IRequestResult<IFeedForHour>> {
  return hyRequest.post<IRequestResult<IFeedForHour>>({
    url: DashboardApi.findFeedForHour,
    data: params
  })
}
