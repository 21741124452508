import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import {
  ITotalData,
  ITrendData,
  ISparePartsDetail,
  ISparePartsInfo,
  ISparePartsTotal
} from '@/service/purchase/spareParts/type'
enum DashboardApi {
  findSparePartsTrend = '/purchaseData/findSparePartsTrend',
  findSparePartsCountByDateType = '/purchaseData/findSparePartsCountByDateType',
  findSparePartsDetailByPage = '/purchaseData/findSparePartsDetailByPage',
  findSparePartsDetailInfo = '/purchaseData/findSparePartsDetailInfo',
  findSparePartsTotal = '/purchaseData/findSparePartsDetailStatistics'
}

export function findSparePartsTrend(data: {
  type: number
  date: string
  rangeType: number
  rangeNum: number
}): Promise<IRequestResult<ITrendData>> {
  return hyRequest.post<IRequestResult<ITrendData>>({
    url: DashboardApi.findSparePartsTrend,
    data
  })
}

//按时间类型查询备品备件统计
export function findSparePartsCountByDateType(data: {
  type: number
  date: string
}): Promise<IRequestResult<ITotalData>> {
  return hyRequest.post<IRequestResult<ITotalData>>({
    url: DashboardApi.findSparePartsCountByDateType,
    data
  })
}

// 备品备件明细分页列表
export function findSparePartsDetailByPage(data: {
  type: number
  date: string
  pageNumber: number
  pageSize: number
}): Promise<IRequestResult<ISparePartsDetail>> {
  return hyRequest.post<IRequestResult<ISparePartsDetail>>({
    url: DashboardApi.findSparePartsDetailByPage,
    data
  })
}

// 备品备件明细下拉
export function findSparePartsDetailInfo(data: {
  type: number
  date: string
  stoneName: string
}): Promise<IRequestResult<{ list: ISparePartsInfo[] }>> {
  return hyRequest.post<IRequestResult<{ list: ISparePartsInfo[] }>>({
    url: DashboardApi.findSparePartsDetailInfo,
    data
  })
}

//备品备件明细统计
export function findSparePartsTotal(data: {
  type: number
  date: string
}): Promise<IRequestResult<ISparePartsTotal>> {
  return hyRequest.post<IRequestResult<ISparePartsTotal>>({
    url: DashboardApi.findSparePartsTotal,
    data
  })
}
