import { ref, Ref, computed, ComputedRef } from 'vue'
import {
  IAggregateSaleData,
  IAggregateSaleDataParams
} from '@/service/purchase/reportAggregate/type'
import { getAggregateSaleData } from '@/service/purchase/reportAggregate'

export default function useGuliaoSaleData(
  params: Ref<IAggregateSaleDataParams>
): [
  Ref<IAggregateSaleData>,
  ComputedRef<
    {
      num: number
      desc: string
    }[]
  >,
  ComputedRef<
    {
      text: string
      value: string
    }[]
  >,
  () => Promise<{
    text: string
    value: string
  }>
] {
  const aggregateSaleData = ref<IAggregateSaleData>({
    totMoneyList: [],
    settleValueList: [],
    totalSettleValue: 0,
    totalMoney: 0,
    totalAveragePrice: 0,
    totalSingular: 0,
    timeRange: '',
    rawMaterialMoney: 0,
    totalFreight: 0
  })

  // 获取主机汇总数据
  const getAggregateSaleDataAsync = async () => {
    const res = await getAggregateSaleData(params.value)
    aggregateSaleData.value = res.data

    return {
      text: res.data.settleValueList[0]?.name,
      value: res.data.settleValueList[0]?.supplier_no
    }
  }

  const summaryData = computed(() => {
    return [
      { num: aggregateSaleData.value.totalSettleValue, desc: '总进料量/吨' },
      { num: aggregateSaleData.value.totalMoney, desc: '总金额/万元' },
      { num: aggregateSaleData.value.rawMaterialMoney, desc: '原料费/万元' },
      { num: aggregateSaleData.value.totalFreight, desc: '运费/万元' },
      { num: aggregateSaleData.value.totalSingular, desc: '总车次/次' },
      { num: aggregateSaleData.value.totalAveragePrice, desc: '总均价/元' }
    ]
  })

  const columns = computed(() => {
    return aggregateSaleData.value.settleValueList.map((item) => {
      return {
        text: item.name,
        value: item.supplier_no
      }
    })
  })

  return [aggregateSaleData, summaryData, columns, getAggregateSaleDataAsync]
}
