import { computed, ref, Ref } from 'vue'
import { ISparePartsTotal } from '@/service/purchase/spareParts/type'
import { findSparePartsTotal } from '@/service/purchase/spareParts'

export default function useDetailTotal(
  params: Ref<{
    type: number
    date: string
  }>
): [Ref<ISparePartsTotal>, () => Promise<void>] {
  const totalData = ref<ISparePartsTotal>({
    tot_money: 0,
    tot_cnt: 0
  })

  const findSparePartsTotalAsync = async () => {
    const res = await findSparePartsTotal(params.value)
    totalData.value = res.data
  }

  return [totalData, findSparePartsTotalAsync]
}
