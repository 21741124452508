import { ref, Ref, computed, ComputedRef } from 'vue'
import { findSparePartsCountByDateType } from '@/service/purchase/spareParts'
import { ITotalData } from '@/service/purchase/spareParts/type'

export default function useTotalData(
  params: Ref<{
    date: string
    type: number
  }>
): [
  Ref<ITotalData>,
  ComputedRef<
    {
      num: number
      desc: string
    }[]
  >,
  () => Promise<void>
] {
  const totalData = ref<ITotalData>({
    totMoney: 0,
    singular: 0
  })
  const getTotalDataAsync = async () => {
    const res = await findSparePartsCountByDateType(params.value)
    totalData.value = res.data
  }
  const summaryData = computed(() => {
    let type = ''
    if (params.value.type === 0) {
      type = '本日'
    }
    if (params.value.type === 1) {
      type = '本周'
    }
    if (params.value.type === 2) {
      type = '本月'
    }
    if (params.value.type === 3) {
      type = '本年'
    }
    if (params.value.type === 4) {
      type = '总'
    }
    return [
      { num: totalData.value?.singular, desc: `${type}单数/单` },
      { num: totalData.value?.totMoney, desc: `${type}金额/万` }
    ]
  })

  return [totalData, summaryData, getTotalDataAsync]
}
