import { ref, ComputedRef, computed } from 'vue'
import {
  IHourFeedListItem,
  IAggregateSaleDataParams
} from '@/service/purchase/reportAggregate/type'
import { getHourFeed } from '@/service/purchase/reportAggregate'

export default function useHourFeed(
  params: ComputedRef<IAggregateSaleDataParams>
): [
  ComputedRef<{
    xLabels: string[]
    lineData: {
      supplier: string
      value: number
    }[][]
    legendData: string[]
    colors: string[]
    grid: {
      left: number
      top: number
      bottom: number
      right: number
    }
    legendBottom: number
    customTooltip: boolean
    tooptipFormatter: (params: any) => string
  }>,
  () => Promise<void>
] {
  const feedingData = ref<IHourFeedListItem[]>([])

  const getHourFeedAsync = async () => {
    feedingData.value.splice(0)
    const res = await getHourFeed(params.value)
    feedingData.value = res.data.list
  }
  const dayData = computed(() => {
    const colors = ['#82A3FF', '#5AC57F', '#EEC324']
    const lineData =
      feedingData.value[0]?.value.map((item, index) => {
        return feedingData.value.map((iten) => {
          colors[index] = iten.value[index].color
          return iten.value[index]
        })
      }) ?? []

    const xLabels = feedingData.value.map((item) => item.time)
    const legendData =
      feedingData.value[0]?.value.map((item) => item.supplier) ?? []

    return {
      xLabels,
      lineData,
      legendData,
      colors: colors,
      grid: {
        left: 55,
        top: 10,
        bottom: 120,
        right: 20
      },
      legendBottom: 2,
      customTooltip: true,
      tooptipFormatter: (
        params1: {
          axisValue: string
          marker: string
          seriesName: string
          value: string
        }[]
      ) => {
        let res = `
                  <div >
                    <div class="flex align-center">
                      <span class="ml-2">${params1[0].axisValue}</span>

                    </div>
                  `
        params1.forEach((item) => {
          res += `
                    <div >
                      ${item.marker}
                      <span style="font-size: 12px;">${item.seriesName}</span>
                      <span style="font-weight:bold;font-size: 12px;">${
                        item.value
                      }${
            params.value.type === 0
              ? '吨'
              : params.value.type === 1
              ? '车'
              : '万元'
          }</span>
                    </div>
                  `
        })
        res += '</div>'
        return res
      }
    }
  })

  return [dayData, getHourFeedAsync]
}
