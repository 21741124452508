import { computed, ref, Ref } from 'vue'
import { findSparePartsDetailByPage } from '@/service/purchase/spareParts'
import { ISparePartsDetailItem } from '@/service/purchase/spareParts/type'

export default function useSparePartsDetail(
  params: Ref<{
    type: number
    date: string
    pageNumber: number
    pageSize: number
  }>
): [
  Ref<ISparePartsDetailItem[]>,
  Ref<boolean>,
  Ref<boolean>,
  () => Promise<void>,
  () => void
] {
  const loading = ref(false)
  const finished = ref(false)
  const detailData = ref<ISparePartsDetailItem[]>([])
  const findSparePartsDetailByPageAsync = async () => {
    try {
      loading.value = true
      const res = await findSparePartsDetailByPage(params.value)
      detailData.value.push(...res.data.list)
      // 加载状态结束
      loading.value = false
      // 数据全部加载完成
      if (res.data.totalRow === detailData.value.length) {
        finished.value = true
      }
    } catch (error) {
      params.value.pageNumber -= 1
    }
  }

  const onLoad = () => {
    params.value.pageNumber += 1
    findSparePartsDetailByPageAsync()
  }

  return [
    detailData,
    loading,
    finished,
    findSparePartsDetailByPageAsync,
    onLoad
  ]
}
