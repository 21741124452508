import { ref, Ref } from 'vue'
import {
  IAggregateSaleDataParams,
  IAggregateSaleTrendItem
} from '@/service/purchase/reportShangTong/type'
import { getAggregateSaleTrend } from '@/service/purchase/reportShangTong'

export default function useShangTongTrend(
  params: Ref<IAggregateSaleDataParams>
): [
  Ref<IAggregateSaleTrendItem[]>,
  Ref<IAggregateSaleTrendItem[]>,
  () => Promise<void>,
  () => Promise<void>
] {
  const aggregateSaleTrendData = ref<IAggregateSaleTrendItem[]>([])
  const aggregateSaleTrendYearData = ref<IAggregateSaleTrendItem[]>([])
  // 获取主机汇总数据
  const getAggregateSaleTrendAsync = async () => {
    let num = 30
    switch (params.value.type) {
      case 1:
        num = 30
        break
      case 2:
        num = 30
        break
      case 3:
        num = 12
        break
      case 4:
        num = 10
        break
      default:
        num = 30
    }
    const res = await getAggregateSaleTrend({
      ...params.value,
      rangeType: params.value.type,
      rangeNum: num
    })
    aggregateSaleTrendData.value = res.data.list
  }

  const getAggregateSaleTrendYearAsync = async () => {
    const res = await getAggregateSaleTrend({
      type: 3,
      date: params.value.date,
      rangeType: -1,
      rangeNum: 3
    })
    aggregateSaleTrendYearData.value = res.data.list
  }

  return [
    aggregateSaleTrendData,
    aggregateSaleTrendYearData,
    getAggregateSaleTrendAsync,
    getAggregateSaleTrendYearAsync
  ]
}
