import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import { IDetailParams, IPurchaseDetailData } from './type'

enum DashboardApi {
  getAggregatePurchaseDetail = '/purchaseData/aggregatePurchaseDetail',
  getCommercialPurchaseDetail = '/purchaseData/commercialPurchaseDetail',
  getMortarPurchaseDetail = '/purchaseData/mortarPurchaseDetail'
}

// 查询骨料采购明细
export function getAggregatePurchaseDetail(
  data: IDetailParams
): Promise<IRequestResult<IPurchaseDetailData>> {
  return hyRequest.post<IRequestResult<IPurchaseDetailData>>({
    url: DashboardApi.getAggregatePurchaseDetail,
    data
  })
}
// 查询商砼采购明细
export function getCommercialPurchaseDetail(
  data: IDetailParams
): Promise<IRequestResult<IPurchaseDetailData>> {
  return hyRequest.post<IRequestResult<IPurchaseDetailData>>({
    url: DashboardApi.getCommercialPurchaseDetail,
    data
  })
}
// 查询砂浆采购明细
export function getMortarPurchaseDetail(
  data: IDetailParams
): Promise<IRequestResult<IPurchaseDetailData>> {
  return hyRequest.post<IRequestResult<IPurchaseDetailData>>({
    url: DashboardApi.getMortarPurchaseDetail,
    data
  })
}
