import { ref, Ref } from 'vue'
import {
  IAggregateSupplierContrastDataItem,
  IAggregateSupplierContrastParams
} from '@/service/purchase/reportAggregate/type'
import { getAggregateSupplierContrast } from '@/service/purchase/reportAggregate'

export default function useGuliaoSaleSupplierContrast(
  params: Ref<IAggregateSupplierContrastParams>
): [Ref<IAggregateSupplierContrastDataItem[]>, () => Promise<void>] {
  const supplierContrastData = ref<IAggregateSupplierContrastDataItem[]>([])

  // 获取主机汇总数据
  const getAggregateSupplierContrastAsync = async () => {
    const res = await getAggregateSupplierContrast(params.value)
    supplierContrastData.value = res.data.list
  }

  return [supplierContrastData, getAggregateSupplierContrastAsync]
}
