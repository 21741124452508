import { computed, ref, Ref } from 'vue'
import { findSparePartsDetailInfo } from '@/service/purchase/spareParts'
import { ISparePartsInfo } from '@/service/purchase/spareParts/type'

export default function useSparePartsDetailList(
  params: Ref<{
    type: number
    date: string
    stoneName: string
  }>
): [Ref<ISparePartsInfo[]>, () => Promise<void>] {
  const listData = ref<ISparePartsInfo[]>([])
  const findSparePartsDetailInfoAsync = async () => {
    const res = await findSparePartsDetailInfo(params.value)
    listData.value = res.data.list
  }

  return [listData, findSparePartsDetailInfoAsync]
}
