import { ref, Ref } from 'vue'
import {
  IAggregateSaleDataParams,
  IAggregateSaleTrendItem
} from '@/service/purchase/reportAggregate/type'
import { getAggregateSaleTrend } from '@/service/purchase/reportAggregate'

export default function useAggregateTrend(
  params: Ref<IAggregateSaleDataParams>
): [
  Ref<IAggregateSaleTrendItem[]>,
  Ref<IAggregateSaleTrendItem[]>,
  () => Promise<void>,
  () => Promise<void>
] {
  const aggregateSaleTrendData = ref<IAggregateSaleTrendItem[]>([])
  const aggregateSaleTrendYearData = ref<IAggregateSaleTrendItem[]>([])
  // 获取主机汇总数据
  const getAggregateSaleTrendAsync = async () => {
    let num = 30
    let type = 0
    switch (params.value.type) {
      // 日
      case 0:
        num = 30
        type = 0
        break
      // 周
      case 1:
        num = 30
        type = 1
        break
      // 月
      case 2:
        num = 12
        // num = 30
        type = 2
        break
      // 年
      case 3:
        num = 3
        // num = 12
        type = 3
        break
      // 自定义
      case 4:
        num = 10
        type = 4
        break
    }
    const res = await getAggregateSaleTrend({
      // ...params.value,
      // rangeType: params.value.type,
      // rangeNum: num
      type: type,
      date: params.value.date,
      rangeType: type,
      rangeNum: num
    })
    aggregateSaleTrendData.value = res.data.list
  }

  const getAggregateSaleTrendYearAsync = async () => {
    const res = await getAggregateSaleTrend({
      type: 3,
      date: params.value.date,
      rangeType: -1,
      rangeNum: 3
    })
    aggregateSaleTrendYearData.value = res.data.list
  }

  return [
    aggregateSaleTrendData,
    aggregateSaleTrendYearData,
    getAggregateSaleTrendAsync,
    getAggregateSaleTrendYearAsync
  ]
}
