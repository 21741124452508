import "core-js/modules/es.number.constructor.js";
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'RankListItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    detailList: {
      type: Array
    }
  },
  setup: function setup() {
    return {};
  }
});