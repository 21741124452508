import { ref, Ref } from 'vue'
import { findSparePartsTrend } from '@/service/purchase/spareParts'
import { ITrendDataItem } from '@/service/purchase/spareParts/type'

export default function useEchartData(
  params: Ref<{
    date: string
    type: number
  }>
): [
  Ref<ITrendDataItem[]>,
  Ref<ITrendDataItem[]>,
  () => Promise<void>,
  () => Promise<void>
] {
  const trendData = ref<ITrendDataItem[]>([])
  const trendData1 = ref<ITrendDataItem[]>([])
  const getSparePartsAsync = async () => {
    let num = 30
    switch (params.value.type) {
      case 1:
        num = 30
        break
      case 2:
        num = 30
        break
      case 3:
        num = 12
        break
      case 4:
        num = 10
        break
      default:
        num = 30
    }
    const res = await findSparePartsTrend({
      ...params.value,
      rangeType: params.value.type,
      rangeNum: num
    })
    trendData.value = res.data.list
  }

  const getSparePartsYearAsync = async () => {
    const res = await findSparePartsTrend({
      type: 3,
      date: params.value.date,
      rangeType: -1,
      rangeNum: 3
    })
    trendData1.value = res.data.list
  }

  return [trendData, trendData1, getSparePartsAsync, getSparePartsYearAsync]
}
